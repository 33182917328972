.myBG{
    height: 100vh;
    background-color: #000000;
}
.intro{
	width: 100%;
	height: 93vh;
	position: relative;
	background: url('../assets/images/movie.webp') no-repeat center center/cover;
}
.intro::after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: rgba(0, 0, 0, 0.6);
	box-shadow: inset 120px 100px 250px #000000, inset -120px -100px 250px #000000;
}
.content-intro  {
	position: relative;
	z-index: 2;
	width: 65%;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-top: 9rem;
}
.title{
    font-size: 3rem;
    font-weight: 800;
    line-height: 1;
    text-shadow: 5px 2px black;
    letter-spacing: 3px;
    color: rgb(255, 255, 168);
}
.trending{
    height: auto;
    background-color: #020824;
    background-size: cover;
    /* opacity: 75%; */
}
.movieImage{
    height: auto;
    margin: 5px;
    padding: 5px;
}
.movieWrapper{
min-height: fit-content;
}
.card-title{
   
}
.imgWrapper{
    height: 500px;
}
.superhero{
    height: auto;
    background-color: #020824;
    background-size: cover;
    /* opacity: 75%; */
}
.navbar-nav{
    font-size: x-large;
}
.collapsible {
    background-color: #777;
    color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
  }
  
  .active, .collapsible:hover {
    background-color: #555;
  }
  
  .content {
    padding: 0 18px;
    display: none;
    overflow: hidden;

  }
  .container-rm{
    top: 10%;
    left: 23%;
    width: 50%;
  }
    
  .text{
    display: inline;
    width: 100%;
  }
    
  .read-or-hide{
    color: rgb(224, 59, 59);
    cursor: pointer;
  }